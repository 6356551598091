'use client'
import * as React from 'react';
import { Box, styled, Typography } from '@mui/material';
import { CalendlyProps } from '../lib/types';
import {
  addScriptToPage,
  getCalendlyUrl,
  isCalendlyEvent,
  removeScriptFromPage,
} from '../lib/lib';
import { useEffect, useState } from 'react';
import { Preloader } from '../../preloader';

export const Calendly = (props: CalendlyProps) => {
  const [showDisclamer, setShowDisclamer] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [url, setUrl] = useState<string>();

  useEffect(() => {
    let reset = false;

    getCalendlyUrl(props.dataUrl, props.type, props.agent, props.client, props.source).then(
      (result) => {
        if (!reset) {
          setLoading(true);
          setUrl(result);
        }
      }
    );

    return () => {
      reset = true;
    };
  }, [props.dataUrl, props.type, props.agent]);

  useEffect(() => {
    const listener = (message: MessageEvent<any>) => {
      if (isCalendlyEvent(message)) {
        if (message.data.event === 'calendly.event_type_viewed') {
          setLoading(false);
        }
        if (message.data.event === 'calendly.event_scheduled') {
          setShowDisclamer(true);
          props.onEventScheduled && props.onEventScheduled(message.data);
        }
      }
    };

    if (url) {
      addScriptToPage();
      window.addEventListener('message', listener);
    }
    return () => {
      removeScriptFromPage();
      window.removeEventListener('message', listener);
    };
  }, [url]);

  if (!url) {
    return <Preloader sx={{ my: 6 }} />;
  }

  return (
    <div
      style={{
        width: '100%',
        maxWidth: props.maxWidth || '700px',
        position: 'relative',
      }}
    >
      {loading && <Preloader sx={{ my: 6 }} />}

      <Box
        className="calendly-inline-widget"
        data-url={url}
        sx={{
          width: '100%',
          maxWidth: props.maxWidth || '700px',
          height: loading ? 0 : props.height || 750,
          ...props.sx,
        }}
      />

      <Disclamer
        sx={{
          display: showDisclamer ? 'block' : 'none',
        }}
        className={'fs-18 lh-22'}
      >
        For accommodations for persons with special needs at
        <br />
        meetings, please call (888) 969-7667 | (TTY 711) at the time of
        <br />
        your meeting.
      </Disclamer>
    </div>
  );
};

const Disclamer = styled(Typography)({
  background: 'white',
  width: '100%',
  maxWidth: 654,
  zIndex: '1',
  margin: 'auto',
  paddingBottom: '35px',
  paddingTop: '10px',
  color: '#778E95',
  boxShadow: '0px 2px 8px 0px rgb(0 0 0 / 8%)',
  textAlign: 'center',
});
