import { CalendlyCallType, Client } from './types';
export function isCalendlyEvent(e: MessageEvent) {
  return e.data.event && e.data.event.indexOf('calendly') === 0;
}

export const addScriptToPage = () => {
  if (!document.querySelector('#calendly-js')) {
    const script = document.createElement('script');
    script.src = 'https://assets.calendly.com/assets/external/widget.js';
    script.async = true;
    script.id = 'calendly-js';
    document.body.appendChild(script);
  }
};

export const removeScriptFromPage = () => {
  document.querySelector('#calendly-js')?.remove();
};

export async function getCalendlyUrl(
  initialUrl = '',
  type = CalendlyCallType.consultation,
  agent?: {enrollmentMeetingUrl?: string | null, planReviewUrl?: string | null, b2bMeetingUrl?: string | null, consultationUrl?: string | null},
  client?: Client,
  source?: string,
) {
  if (initialUrl) {
    return decorate(initialUrl, client, source);
  }

  const isProduction = process.env.NX_PRODUCTION === 'true';
  if (!isProduction) {
    if (type === CalendlyCallType.b2b) {
      return decorate('https://calendly.com/coverright/b2b-staging', client, source);
    }
    return decorate('https://calendly.com/coverright/15min', client, source);
  }

  let url = `https://calendly.com/coverright-advisors/medicare-consultation-client`;
  let typedUrl: string | undefined | null = '';

  switch (type) {
    case CalendlyCallType.enrollment:
      typedUrl = agent?.enrollmentMeetingUrl;
      break;
    case CalendlyCallType.planReview:
      typedUrl = agent?.planReviewUrl;
      break;
    case CalendlyCallType.b2b:
      typedUrl = agent?.b2bMeetingUrl;
      break;
  }

  url = typedUrl || agent?.consultationUrl || url

  return decorate(url, client, source);
}

const decorate = (url: string, client?: Client, source?: string) => {
  const params = new URLSearchParams();
  params.set('primary_color' , '1C434F');
  if (client?.firstName) {
    params.set('name' , [client?.firstName, client?.lastName].filter(Boolean).join(' '));
  }
  if (client?.email) {
    params.set('email' , client?.email);
  }
  if (client?.phoneNumber) {
    params.set('a1' , client?.phoneNumber);
  }

  if (source) {
    params.set('utm_source' , source);
  }

  return `${url}?${params.toString()}`
}
