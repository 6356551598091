import React, { ReactNode } from 'react';
import { Link } from '@mui/material';

type DisclamersType = 'PERSONAL_INFO' | 'PERSONAL_INFO_ONBOARDING' | 'SCHEDULE_A_CALL';

export const getDisclamers = (type: DisclamersType, data?: {maOrgCount: string, maPlansCount: string}): React.ReactNode => {
  const getMedicareDisclamer = (children: ReactNode, multiplan = 'MULTIPLAN_COVERRIGHT_2025_M'): React.ReactNode => (
    <>
      By submitting my phone number and personal information above and clicking ‘Submit’ above, I confirm that
      the phone number provided is my own, and I provide my express written consent to be contacted by
      CoverRight Insurance Services Inc. or one of its licensed insurance agents at the phone
      number I have provided above regarding various Medicare products including Medicare Advantage Plans,
      Medicare Supplement (Medigap) Plans, Prescription Drug Plans and other marketing and informational
      content, through the use of text message, email and/or live, automated and pre-recorded telephone calls, AI generative voice calls, prerecorded messages played when a connection is made, or prerecorded voicemail
      messages even if my number is on a Do Not Call list. I further understand and agree that my communications
      with CoverRight Inc. are subject to CoverRight Inc.'s <Link href={'https://coverright.com/privacy/'}
                                                               target={'_blank'}>Privacy Policy</Link> and <Link
      href={'https://coverright.com/coverright-terms-of-use/'} target={'_blank'}>Terms of Use</Link>.
      <br />
      <br />
      This is a solicitation for insurance. Your information will be
      provided to a licensed agent for future contact.
      <br />
      <br />
      I understand my consents to receive text messages and telephone
      calls are not required to receive services from CoverRight Inc.; I
      may instead reach CoverRight Inc. directly at +1 (888) 969-7667
      (TTY: 711) and I can revoke my consents at any time by emailing
      hello@coverright.com.
      <br />
      <br />
      CoverRight Insurance Services Inc. represents Medicare Advantage
      HMO, PPO and PFFS organizations that have a Medicare contract.
      Enrollment depends on the plan’s contract renewal with Medicare.
      <br />
      <br />
      {(data?.maOrgCount && data?.maPlansCount) ? <>
          We do not offer every plan available in your area. Currently we represent {data?.maOrgCount} organizations which
          offer {data?.maPlansCount} products in your area. Please contact Medicare.gov, 1–800–MEDICARE, or
          your local State Health Insurance Program (SHIP) to get information on all of your options.
        </> :
        <>We do not offer every plan available in your area. Currently we represent 30 organizations across the nation
          and 3923 plans across the nation. Please contact Medicare.gov, 1-800-MEDICARE, or your local State Health
          Insurance Program (SHIP) to get information on all of your options.</>}
      <br /><br />
      {children}
      {multiplan}
    </>
  )
  const disclamers = {
    'PERSONAL_INFO': getMedicareDisclamer(null),
    'PERSONAL_INFO_ONBOARDING': getMedicareDisclamer(<>
      Not all plans offer all of these benefits. Benefits may vary by carrier and location. Limitations and exclusions
      may apply.
      <br /><br />
      Enrollment in a plan may be limited to certain times of the year unless you qualify for a Special Enrollment
      Period or you are in your Medicare Initial Enrollment Period.
      <br /><br />
    </>, 'MULTIPLAN_COVERRIGHT_2025_HP_M'),
    'SCHEDULE_A_CALL': getMedicareDisclamer(<>
      Not all plans offer all of these benefits. Benefits may vary by carrier and location. Limitations and exclusions
      may apply.
      <br /><br />
    </>, ''),

  }

  return disclamers[type]
}
